import { IconInterface } from "@/components/Icon/IconInterface";

export const Logo = (props: IconInterface) => {
  return (
    <svg
      width="182.615px"
      height="84.661px"
      {...props}
      viewBox="0 0 182.615 84.661"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xlinkHref="http://www.w3.org/1999/xlink"
    >
      <title>Logo</title>
      <defs>
        <polygon id="path-1" points="0 0 6.248 0 6.248 7.5379 0 7.5379"></polygon>
        <polygon id="path-3" points="0 0 6.248 0 6.248 7.5379 0 7.5379"></polygon>
        <polygon id="path-5" points="0 84.661 182.615 84.661 182.615 0 0 0"></polygon>
        <path
          d="M0,35.825 L141.752,35.825 L141.752,2.047 C141.752,2.047 104.683,20.57 68.114,20.57 L68.114,20.57 C24.575,20.57 2.179,2.302 0,0 L0,0 L0,35.825 Z"
          id="path-7"
        ></path>
        <path
          d="M0,35.825 L141.752,35.825 L141.752,2.047 C141.752,2.047 104.683,20.57 68.114,20.57 L68.114,20.57 C24.575,20.57 2.179,2.302 0,0 L0,0"
          id="path-9"
        ></path>
        <path
          d="M0,35.825 L141.752,35.825 L141.752,2.047 C141.752,2.047 104.683,20.57 68.114,20.57 L68.114,20.57 C24.575,20.57 2.179,2.302 0,0 L0,0 L0,35.825 Z"
          id="path-11"
        ></path>
      </defs>
      <g id="Logo" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group-48">
          <path
            d="M1.9352,83.2298 L3.1652,83.2298 C4.4952,83.2298 5.2202,82.7458 5.2202,80.8918 C5.2202,79.4808 4.6062,78.5538 3.1342,78.5538 L1.9352,78.5538 L1.9352,83.2298 Z M0.5852,77.3048 L3.1042,77.3048 C5.5832,77.3048 6.6312,78.8358 6.6312,80.8918 C6.6312,83.2298 5.4922,84.4798 3.3562,84.4798 L0.5852,84.4798 L0.5852,77.3048 Z"
            id="Fill-1"
            fill="#DE007B"
          ></path>
          <polygon
            id="Fill-3"
            fill="#DE007B"
            points="8.1831 77.3044 13.2621 77.3044 13.2621 78.5544 9.5331 78.5544 9.5331 80.1864 12.9591 80.1864 12.9591 81.3754 9.5331 81.3754 9.5331 83.2294 13.5241 83.2294 13.5241 84.4794 8.1831 84.4794"
          ></polygon>
          <path
            d="M16.1943,80.65 L17.6663,80.65 C18.3513,80.65 18.8453,80.247 18.8453,79.572 C18.8453,78.917 18.4013,78.493 17.4643,78.493 L16.1943,78.493 L16.1943,80.65 Z M14.8443,77.304 L17.8363,77.304 C19.3583,77.304 20.2553,78.212 20.2553,79.471 C20.2553,81.083 19.1773,81.456 18.8343,81.587 L20.4373,84.479 L18.8753,84.479 L17.4843,81.839 L16.1943,81.839 L16.1943,84.479 L14.8443,84.479 L14.8443,77.304 Z"
            id="Fill-5"
            fill="#DE007B"
          ></path>
          <polygon
            id="Fill-7"
            fill="#DE007B"
            points="25.0015 77.3044 26.3515 77.3044 26.3515 79.9944 29.4355 79.9944 29.4355 77.3044 30.7855 77.3044 30.7855 84.4794 29.4355 84.4794 29.4355 81.2444 26.3515 81.2444 26.3515 84.4794 25.0015 84.4794"
          ></polygon>
          <path
            d="M35.1287,78.6147 L35.1087,78.6147 L34.1207,81.8997 L36.1167,81.8997 L35.1287,78.6147 Z M34.3327,77.3047 L35.9047,77.3047 L38.3027,84.4797 L36.8917,84.4797 L36.4787,83.0887 L33.7587,83.0887 L33.3447,84.4797 L31.9337,84.4797 L34.3327,77.3047 Z"
            id="Fill-9"
            fill="#DE007B"
          ></path>
          <path
            d="M45.236,82.2726 C45.236,83.7336 44.077,84.6606 42.344,84.6606 C40.611,84.6606 39.452,83.7336 39.452,82.2726 L39.452,77.3046 L40.802,77.3046 L40.802,81.9196 C40.802,82.7966 41.175,83.3506 42.344,83.3506 C43.513,83.3506 43.886,82.7966 43.886,81.9196 L43.886,77.3046 L45.236,77.3046 L45.236,82.2726 Z"
            id="Fill-11"
            fill="#DE007B"
          ></path>
          <g id="Group-15" transform="translate(46.394800, 77.123100)">
            <mask id="mask-2" fill="white">
              <use xlinkHref="#path-1"></use>
            </mask>
            <g id="Clip-14"></g>
            <path
              d="M1.149,5.2 C1.653,5.945 2.439,6.288 3.315,6.288 C3.971,6.288 4.837,6.066 4.837,5.371 C4.837,4.807 4.313,4.645 3.356,4.424 L2.559,4.242 C1.411,3.981 0.403,3.557 0.403,2.237 C0.403,0.725 1.844,0 3.184,0 C4.414,0 5.431,0.453 6.177,1.441 L5.058,2.288 C4.716,1.632 4.051,1.25 3.174,1.25 C2.398,1.25 1.814,1.542 1.814,2.046 C1.814,2.64 2.489,2.781 3.295,2.963 L4.172,3.154 C5.24,3.386 6.248,3.819 6.248,5.18 C6.248,6.933 4.595,7.538 3.104,7.538 C1.914,7.538 0.685,7.024 0,6.026 L1.149,5.2 Z"
              id="Fill-13"
              fill="#DE007B"
              mask="url(#mask-2)"
            ></path>
          </g>
          <polygon
            id="Fill-16"
            fill="#DE007B"
            points="54.084 77.3044 55.434 77.3044 55.434 79.9944 58.518 79.9944 58.518 77.3044 59.868 77.3044 59.868 84.4794 58.518 84.4794 58.518 81.2444 55.434 81.2444 55.434 84.4794 54.084 84.4794"
          ></polygon>
          <path
            d="M64.2113,78.6147 L64.1913,78.6147 L63.2033,81.8997 L65.1993,81.8997 L64.2113,78.6147 Z M63.4153,77.3047 L64.9873,77.3047 L67.3853,84.4797 L65.9743,84.4797 L65.5613,83.0887 L62.8413,83.0887 L62.4273,84.4797 L61.0163,84.4797 L63.4153,77.3047 Z"
            id="Fill-18"
            fill="#DE007B"
          ></path>
          <polygon
            id="Fill-20"
            fill="#DE007B"
            points="68.5345 77.3044 69.8845 77.3044 69.8845 83.2294 72.7975 83.2294 72.7975 84.4794 68.5345 84.4794"
          ></polygon>
          <polygon
            id="Fill-22"
            fill="#DE007B"
            points="74.7119 78.554 72.7469 78.554 72.7469 77.304 78.0269 77.304 78.0269 78.554 76.0619 78.554 76.0619 84.479 74.7119 84.479"
          ></polygon>
          <g id="Group-26" transform="translate(78.601000, 77.123100)">
            <mask id="mask-4" fill="white">
              <use xlinkHref="#path-3"></use>
            </mask>
            <g id="Clip-25"></g>
            <path
              d="M1.149,5.2 C1.653,5.945 2.439,6.288 3.316,6.288 C3.971,6.288 4.837,6.066 4.837,5.371 C4.837,4.807 4.313,4.645 3.356,4.424 L2.56,4.242 C1.411,3.981 0.404,3.557 0.404,2.237 C0.404,0.725 1.844,0 3.185,0 C4.415,0 5.432,0.453 6.178,1.441 L5.059,2.288 C4.717,1.632 4.051,1.25 3.175,1.25 C2.399,1.25 1.814,1.542 1.814,2.046 C1.814,2.64 2.489,2.781 3.296,2.963 L4.173,3.154 C5.241,3.386 6.248,3.819 6.248,5.18 C6.248,6.933 4.596,7.538 3.104,7.538 C1.915,7.538 0.686,7.024 0,6.026 L1.149,5.2 Z"
              id="Fill-24"
              fill="#DE007B"
              mask="url(#mask-4)"
            ></path>
          </g>
          <path
            d="M87.6404,83.2298 L88.8704,83.2298 C90.2004,83.2298 90.9254,82.7458 90.9254,80.8918 C90.9254,79.4808 90.3114,78.5538 88.8394,78.5538 L87.6404,78.5538 L87.6404,83.2298 Z M86.2904,77.3048 L88.8094,77.3048 C91.2884,77.3048 92.3364,78.8358 92.3364,80.8918 C92.3364,83.2298 91.1974,84.4798 89.0614,84.4798 L86.2904,84.4798 L86.2904,77.3048 Z"
            id="Fill-27"
            fill="#DE007B"
          ></path>
          <mask id="mask-6" fill="white">
            <use xlinkHref="#path-5"></use>
          </mask>
          <g id="Clip-30"></g>
          <polygon id="Fill-29" fill="#DE007B" mask="url(#mask-6)" points="93.959 84.479 95.309 84.479 95.309 77.304 93.959 77.304"></polygon>
          <path
            d="M97.6869,82.3231 C98.1909,83.0681 98.9769,83.4111 99.8539,83.4111 C100.5089,83.4111 101.3749,83.1891 101.3749,82.4941 C101.3749,81.9301 100.8509,81.7681 99.8939,81.5471 L99.0979,81.3651 C97.9489,81.1041 96.9419,80.6801 96.9419,79.3601 C96.9419,77.8481 98.3819,77.1231 99.7229,77.1231 C100.9529,77.1231 101.9699,77.5761 102.7159,78.5641 L101.5969,79.4111 C101.2549,78.7551 100.5889,78.3731 99.7129,78.3731 C98.9369,78.3731 98.3519,78.6651 98.3519,79.1691 C98.3519,79.7631 99.0269,79.9041 99.8339,80.0861 L100.7109,80.2771 C101.7789,80.5091 102.7859,80.9421 102.7859,82.3031 C102.7859,84.0561 101.1339,84.6611 99.6419,84.6611 C98.4529,84.6611 97.2239,84.1471 96.5379,83.1491 L97.6869,82.3231 Z"
            id="Fill-31"
            fill="#DE007B"
            mask="url(#mask-6)"
          ></path>
          <path
            d="M108.9841,79.7127 C108.8731,78.9167 108.2181,78.3727 107.4021,78.3727 C105.9711,78.3727 105.3761,79.6427 105.3761,80.8917 C105.3761,82.1417 105.9711,83.4107 107.4021,83.4107 C108.3391,83.4107 108.8731,82.7967 109.1351,81.9597 L110.3641,82.5237 C109.7901,83.9657 108.8321,84.6607 107.4021,84.6607 C105.1041,84.6607 103.9651,82.9177 103.9651,80.8917 C103.9651,78.8667 105.2551,77.1227 107.4021,77.1227 C108.8431,77.1227 109.9411,77.8787 110.2841,79.3797 L108.9841,79.7127 Z"
            id="Fill-32"
            fill="#DE007B"
            mask="url(#mask-6)"
          ></path>
          <path
            d="M112.7828,80.8919 C112.7828,82.1419 113.3768,83.4109 114.8088,83.4109 C116.2398,83.4109 116.8338,82.1419 116.8338,80.8919 C116.8338,79.6419 116.2398,78.3729 114.8088,78.3729 C113.3768,78.3729 112.7828,79.6419 112.7828,80.8919 M118.2448,80.8919 C118.2448,82.9179 116.9548,84.6609 114.8088,84.6609 C112.6618,84.6609 111.3718,82.9179 111.3718,80.8919 C111.3718,78.8659 112.6618,77.1229 114.8088,77.1229 C116.9548,77.1229 118.2448,78.8659 118.2448,80.8919"
            id="Fill-33"
            fill="#DE007B"
            mask="url(#mask-6)"
          ></path>
          <path
            d="M125.5811,82.2726 C125.5811,83.7336 124.4221,84.6606 122.6891,84.6606 C120.9561,84.6606 119.7971,83.7336 119.7971,82.2726 L119.7971,77.3046 L121.1471,77.3046 L121.1471,81.9196 C121.1471,82.7966 121.5201,83.3506 122.6891,83.3506 C123.8581,83.3506 124.2311,82.7966 124.2311,81.9196 L124.2311,77.3046 L125.5811,77.3046 L125.5811,82.2726 Z"
            id="Fill-34"
            fill="#DE007B"
            mask="url(#mask-6)"
          ></path>
          <polygon
            id="Fill-35"
            fill="#DE007B"
            mask="url(#mask-6)"
            points="127.4455 77.3044 128.6845 77.3044 131.6975 82.4134 131.7185 82.4134 131.7185 77.3044 132.9475 77.3044 132.9475 84.4794 131.7085 84.4794 128.6945 79.3704 128.6745 79.3704 128.6745 84.4794 127.4455 84.4794"
          ></polygon>
          <polygon
            id="Fill-36"
            fill="#DE007B"
            mask="url(#mask-6)"
            points="136.192 78.554 134.227 78.554 134.227 77.304 139.508 77.304 139.508 78.554 137.543 78.554 137.543 84.479 136.192 84.479"
          ></polygon>
          <polygon
            id="Fill-37"
            fill="#DE007B"
            mask="url(#mask-6)"
            points="140.7367 77.3044 145.8157 77.3044 145.8157 78.5544 142.0867 78.5544 142.0867 80.1864 145.5127 80.1864 145.5127 81.3754 142.0867 81.3754 142.0867 83.2294 146.0777 83.2294 146.0777 84.4794 140.7367 84.4794"
          ></polygon>
          <path
            d="M148.7479,80.65 L150.2199,80.65 C150.9049,80.65 151.3989,80.247 151.3989,79.572 C151.3989,78.917 150.9549,78.493 150.0179,78.493 L148.7479,78.493 L148.7479,80.65 Z M147.3979,77.304 L150.3899,77.304 C151.9119,77.304 152.8089,78.212 152.8089,79.471 C152.8089,81.083 151.7309,81.456 151.3879,81.587 L152.9909,84.479 L151.4289,84.479 L150.0379,81.839 L148.7479,81.839 L148.7479,84.479 L147.3979,84.479 L147.3979,77.304 Z"
            id="Fill-38"
            fill="#DE007B"
            mask="url(#mask-6)"
          ></path>
          <path
            d="M94.1357,70.0696 L94.1357,31.4626 L110.7057,31.4626 C115.0827,31.4626 118.4067,31.6406 120.5867,31.9956 C122.6637,32.3386 124.4847,32.9036 125.9957,33.6756 C128.9107,35.2166 131.1447,37.4166 132.6227,40.2086 C134.1137,43.0406 134.8687,46.6086 134.8687,50.8116 C134.8687,55.2006 133.9357,58.9256 132.0897,61.8776 C130.2727,64.7966 127.5237,67.0096 123.9227,68.4596 C122.5357,69.0286 120.7517,69.4416 118.6207,69.6896 C116.4777,69.9416 112.8977,70.0696 107.9807,70.0696 L94.1357,70.0696 Z M107.6377,60.8826 L111.5687,60.8826 C115.2107,60.8826 117.7707,60.1436 119.1787,58.6856 C120.5947,57.2236 121.3087,54.5776 121.3087,50.8116 C121.3087,47.1406 120.5777,44.4986 119.1327,42.9666 C117.7007,41.4346 115.2187,40.6586 111.7587,40.6586 L107.6377,40.6586 L107.6377,60.8826 Z"
            id="Fill-39"
            fill="#00489C"
            mask="url(#mask-6)"
          ></path>
          <path
            d="M66.0788,71.0854 C58.6998,71.0854 52.8158,69.2354 48.5918,65.5934 C44.4178,61.9934 42.2998,57.0054 42.2998,50.7664 C42.2998,44.5104 44.4138,39.5224 48.5838,35.9304 C52.8118,32.2924 58.6998,30.4514 66.0788,30.4514 C73.4448,30.4514 79.3128,32.2924 83.5198,35.9304 C87.6818,39.5104 89.7878,44.5024 89.7878,50.7664 C89.7878,57.0134 87.6818,62.0014 83.5198,65.5934 C79.3128,69.2354 73.4448,71.0854 66.0788,71.0854 M66.0958,38.3544 C62.5028,38.3544 59.7948,39.4074 58.0478,41.4884 C56.3468,43.5074 55.4878,46.6334 55.4878,50.7824 C55.4878,54.9164 56.3468,58.0334 58.0478,60.0444 C59.7948,62.1254 62.5028,63.1784 66.0958,63.1784 C69.6628,63.1784 72.3468,62.1294 74.0808,60.0524 C75.7618,58.0414 76.6118,54.9204 76.6118,50.7824 C76.6118,46.6204 75.7618,43.4914 74.0808,41.4804 C72.3508,39.4074 69.6628,38.3544 66.0958,38.3544"
            id="Fill-40"
            fill="#00489C"
            mask="url(#mask-6)"
          ></path>
          <path
            d="M30.6655,70.0709 C26.8705,64.9469 23.0805,59.8229 19.2895,54.6939 L18.5345,53.6619 L13.1045,58.4309 L12.6955,58.7699 L12.6955,70.0709 L0.4985,70.0709 L0.4985,31.4639 L12.6955,31.4639 L12.6955,46.7009 L14.7145,44.6979 C19.1705,40.2919 23.6215,35.8819 28.0725,31.4639 L43.1355,31.4639 L27.8575,46.6549 L28.4525,47.4769 C34.0435,55.0119 39.6255,62.5399 45.2165,70.0709 L30.6655,70.0709 Z"
            id="Fill-41"
            fill="#00489C"
            mask="url(#mask-6)"
          ></path>
          <path
            d="M139.034,36.9831 C138.287,32.5651 141.255,28.3781 145.678,27.6271 C150.1,26.8791 154.283,29.8561 155.03,34.2741 C155.777,38.6921 152.809,42.8831 148.39,43.6311 C143.968,44.3821 139.781,41.4011 139.034,36.9831"
            id="Fill-42"
            fill="#00489C"
            mask="url(#mask-6)"
          ></path>
          <path
            d="M148.7063,11.7021 C147.5213,4.7161 140.9563,0.0001 140.9563,0.0001 C140.9563,0.0001 136.3063,6.6151 137.4873,13.6011 C138.6723,20.5881 142.1413,25.8281 145.2383,25.3031 C148.3393,24.7791 149.8913,18.6881 148.7063,11.7021"
            id="Fill-43"
            fill="#DE007B"
            mask="url(#mask-6)"
          ></path>
          <path
            d="M170.9136,37.3456 C177.8996,36.1606 182.6156,29.5956 182.6156,29.5956 C182.6156,29.5956 176.0006,24.9456 169.0146,26.1266 C162.0276,27.3116 156.7876,30.7806 157.3126,33.8776 C157.8366,36.9786 163.9276,38.5306 170.9136,37.3456"
            id="Fill-44"
            fill="#DE007B"
            mask="url(#mask-6)"
          ></path>
          <path
            d="M165.121,19.9243 C169.225,14.1473 167.912,6.1703 167.912,6.1703 C167.912,6.1703 159.951,7.5573 155.847,13.3343 C151.743,19.1113 150.492,25.2713 153.052,27.0923 C155.612,28.9093 161.017,25.7013 165.121,19.9243"
            id="Fill-45"
            fill="#DE007B"
            mask="url(#mask-6)"
          ></path>
          <path
            d="M162.691,53.7599 C168.468,57.8639 176.445,56.5509 176.445,56.5509 C176.445,56.5509 175.058,48.5899 169.281,44.4859 C163.504,40.3819 157.344,39.1309 155.523,41.6909 C153.706,44.2509 156.914,49.6559 162.691,53.7599"
            id="Fill-46"
            fill="#DE007B"
            mask="url(#mask-6)"
          ></path>
          <path
            d="M131.285,17.4948 C125.508,13.3908 117.531,14.7038 117.531,14.7038 C117.531,14.7038 118.918,22.6648 124.695,26.7688 C130.472,30.8728 136.632,32.1238 138.453,29.5638 C140.27,27.0038 137.062,21.5988 131.285,17.4948"
            id="Fill-47"
            fill="#DE007B"
            mask="url(#mask-6)"
          ></path>
        </g>
        <g id="Group-58" transform="translate(0.000000, 42.626000)">
          <g id="Group-51">
            <mask id="mask-8" fill="white">
              <use xlinkHref="#path-7"></use>
            </mask>
            <g id="Clip-50"></g>
            <path
              d="M94.1357,27.4436 L94.1357,-11.1634 L110.7057,-11.1634 C115.0827,-11.1634 118.4067,-10.9854 120.5867,-10.6304 C122.6637,-10.2874 124.4847,-9.7224 125.9957,-8.9504 C128.9107,-7.4094 131.1447,-5.2094 132.6227,-2.4174 C134.1137,0.4146 134.8687,3.9826 134.8687,8.1856 C134.8687,12.5746 133.9357,16.2996 132.0897,19.2516 C130.2727,22.1706 127.5237,24.3836 123.9227,25.8336 C122.5357,26.4026 120.7517,26.8156 118.6207,27.0636 C116.4777,27.3156 112.8977,27.4436 107.9807,27.4436 L94.1357,27.4436 Z M107.6377,18.2566 L111.5687,18.2566 C115.2107,18.2566 117.7707,17.5176 119.1787,16.0596 C120.5947,14.5976 121.3087,11.9516 121.3087,8.1856 C121.3087,4.5146 120.5777,1.8726 119.1327,0.3406 C117.7007,-1.1914 115.2187,-1.9674 111.7587,-1.9674 L107.6377,-1.9674 L107.6377,18.2566 Z"
              id="Fill-49"
              fill="#DE007B"
              mask="url(#mask-8)"
            ></path>
          </g>
          <g id="Group-54">
            <mask id="mask-10" fill="white">
              <use xlinkHref="#path-9"></use>
            </mask>
            <g id="Clip-53"></g>
            <path
              d="M66.0788,28.4594 C58.6998,28.4594 52.8158,26.6094 48.5918,22.9674 C44.4178,19.3674 42.2998,14.3794 42.2998,8.1404 C42.2998,1.8844 44.4138,-3.1036 48.5838,-6.6956 C52.8118,-10.3336 58.6998,-12.1746 66.0788,-12.1746 C73.4448,-12.1746 79.3128,-10.3336 83.5198,-6.6956 C87.6818,-3.1156 89.7878,1.8764 89.7878,8.1404 C89.7878,14.3874 87.6818,19.3754 83.5198,22.9674 C79.3128,26.6094 73.4448,28.4594 66.0788,28.4594 M66.0958,-4.2716 C62.5028,-4.2716 59.7948,-3.2186 58.0478,-1.1376 C56.3468,0.8814 55.4878,4.0074 55.4878,8.1564 C55.4878,12.2904 56.3468,15.4074 58.0478,17.4184 C59.7948,19.4994 62.5028,20.5524 66.0958,20.5524 C69.6628,20.5524 72.3468,19.5034 74.0808,17.4264 C75.7618,15.4154 76.6118,12.2944 76.6118,8.1564 C76.6118,3.9944 75.7618,0.8654 74.0808,-1.1456 C72.3508,-3.2186 69.6628,-4.2716 66.0958,-4.2716"
              id="Fill-52"
              fill="#DE007B"
              mask="url(#mask-10)"
            ></path>
          </g>
          <g id="Group-57">
            <mask id="mask-12" fill="white">
              <use xlinkHref="#path-11"></use>
            </mask>
            <g id="Clip-56"></g>
            <path
              d="M30.6655,27.4449 C26.8705,22.3209 23.0805,17.1969 19.2895,12.0679 L18.5345,11.0359 L13.1045,15.8049 L12.6955,16.1439 L12.6955,27.4449 L0.4985,27.4449 L0.4985,-11.1621 L12.6955,-11.1621 L12.6955,4.0749 L14.7145,2.0719 C19.1705,-2.3341 23.6215,-6.7441 28.0725,-11.1621 L43.1355,-11.1621 L27.8575,4.0289 L28.4525,4.8509 C34.0435,12.3859 39.6255,19.9139 45.2165,27.4449 L30.6655,27.4449 Z"
              id="Fill-55"
              fill="#DE007B"
              mask="url(#mask-12)"
            ></path>
          </g>
        </g>
        <polygon id="Fill-59" fill="#DE007B" points="140.3407 70.3091 140.3407 45.7751 152.8877 45.7751 152.8877 70.3101"></polygon>
      </g>
    </svg>
  );
};
